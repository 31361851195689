var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("成员管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          !_vm.isBatchImport
            ? _c(
                "a-layout",
                [
                  _c(
                    "a-layout-sider",
                    {
                      staticClass: "page-menu-sider",
                      attrs: { theme: "light", width: "400px" },
                    },
                    [
                      _c(
                        "a-card",
                        [
                          _c(
                            "a-tabs",
                            { attrs: { "default-active-key": "org" } },
                            [
                              _c("a-tab-pane", {
                                key: "org",
                                attrs: { tab: "部门" },
                              }),
                            ],
                            1
                          ),
                          _c("a-input-search", {
                            attrs: { placeholder: "搜索部门名称" },
                            on: { search: _vm.searchHandlerClick },
                            model: {
                              value: _vm.searchOrgName,
                              callback: function ($$v) {
                                _vm.searchOrgName = $$v
                              },
                              expression: "searchOrgName",
                            },
                          }),
                          _vm.treeData.length > 0
                            ? _c(
                                "a-tree",
                                {
                                  staticClass: "m-t-16 user-org-tree-custom",
                                  attrs: {
                                    "auto-expand-parent": true,
                                    "expanded-keys": _vm.expandedKeys,
                                    "selected-keys": _vm.selectedKeys,
                                    "tree-data": _vm.treeData,
                                    "block-node": true,
                                    "replace-fields": { key: "id" },
                                  },
                                  on: {
                                    expand: _vm.onExpand,
                                    select: _vm.onSelect,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function (item) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex justify-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "flex" },
                                                  [
                                                    item.orgType ===
                                                    _vm.OrgTreeType.CORP
                                                      ? _c("a-icon", {
                                                          attrs: {
                                                            type: "home",
                                                            theme: "filled",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    item.orgType !==
                                                    _vm.OrgTreeType.CORP
                                                      ? [
                                                          item.expanded
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "tree-icon",
                                                                attrs: {
                                                                  src: "/img/tree-org-expand.png",
                                                                },
                                                              })
                                                            : _c("img", {
                                                                staticClass:
                                                                  "tree-icon",
                                                                attrs: {
                                                                  src: "/img/tree-org.png",
                                                                },
                                                              }),
                                                        ]
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      { staticClass: "m-l-8" },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "a-tag",
                                                      { staticClass: "m-l-16" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatOrgTreeType(
                                                              item.orgType
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                !_vm.IsProject
                                                  ? [
                                                      item.orgType !==
                                                        _vm.OrgTreeType.CORP &&
                                                      item.children.length > 0
                                                        ? _c(
                                                            "a",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.expandHandler(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.expanded
                                                                    ? "收起"
                                                                    : "展开"
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : [
                                                      _c(
                                                        "a-dropdown",
                                                        {
                                                          staticClass:
                                                            "tree-item-action-wrapper",
                                                          attrs: {
                                                            trigger: ["click"],
                                                            placement:
                                                              "bottomRight",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return function (
                                                                e
                                                              ) {
                                                                return e.preventDefault()
                                                              }.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "tree-item-action-dot",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return function (
                                                                      e
                                                                    ) {
                                                                      return e.preventDefault()
                                                                    }.apply(
                                                                      null,
                                                                      arguments
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("a-icon", {
                                                                attrs: {
                                                                  type: "more",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "a-menu",
                                                            {
                                                              attrs: {
                                                                slot: "overlay",
                                                              },
                                                              slot: "overlay",
                                                            },
                                                            [
                                                              _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "0",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.editOrgClick(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "p-12",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "编辑部门"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "1",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.addOrgClick(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "p-12",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "添加子部门"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "2",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.moveClick(
                                                                          item,
                                                                          "UP"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "p-12",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "组织上移"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "a-menu-item",
                                                                {
                                                                  key: "3",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.delOrgClick(
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "p-12",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "组织删除"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3923250038
                                  ),
                                },
                                [
                                  !_vm.IsProject
                                    ? _c("template", { slot: "switcherIcon" }, [
                                        _c("div"),
                                      ])
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "p-12 m-t-20 m-b-20" },
                                [_c("a-empty")],
                                1
                              ),
                          _vm.IsProject
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex m-t-20",
                                  staticStyle: { "justify-content": "center" },
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticStyle: { width: "180px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addOrgClick({ id: null })
                                        },
                                      },
                                    },
                                    [_vm._v("新建项目部门")]
                                  ),
                                  _vm.treeData.length === 0
                                    ? _c(
                                        "a-button",
                                        {
                                          staticClass: "m-l-10",
                                          staticStyle: { width: "180px" },
                                          on: { click: _vm.asyncOrg },
                                        },
                                        [_vm._v("从组织架构同步")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("a-layout-content", [
                    _c(
                      "div",
                      { staticClass: "page-body-margin-left-16" },
                      [
                        _c(
                          "a-card",
                          [
                            _c(
                              "div",
                              { staticClass: "user-list-wrapper-title" },
                              [
                                _vm.selectedOrg.name
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "fz-20 m-r-16" },
                                        [_vm._v(_vm._s(_vm.selectedOrg.name))]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          "总人数：" +
                                            _vm._s(_vm.selectedOrg.userNum)
                                        ),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex m-16 justify-between m-t-20",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("a-input-search", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        placeholder: "请输入姓名、手机、邮箱",
                                      },
                                      on: { search: _vm.searchHandler },
                                      model: {
                                        value: _vm.searchKeyword,
                                        callback: function ($$v) {
                                          _vm.searchKeyword = $$v
                                        },
                                        expression: "searchKeyword",
                                      },
                                    }),
                                    _c(
                                      "a-select",
                                      {
                                        staticClass: "m-l-16",
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "请选择账号状态",
                                          "allow-clear": "",
                                        },
                                        on: { change: _vm.searchHandler },
                                        model: {
                                          value: _vm.searchEnable,
                                          callback: function ($$v) {
                                            _vm.searchEnable = $$v
                                          },
                                          expression: "searchEnable",
                                        },
                                      },
                                      [
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "true" } },
                                          [_vm._v("正常")]
                                        ),
                                        _c(
                                          "a-select-option",
                                          { attrs: { value: "false" } },
                                          [_vm._v("禁用")]
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm.IsProject
                                      ? _c(
                                          "a-select",
                                          {
                                            staticClass: "m-l-16",
                                            staticStyle: { width: "200px" },
                                            attrs: {
                                              placeholder: "包含下级部门成员",
                                            },
                                            on: { change: _vm.searchHandler },
                                            model: {
                                              value: _vm.searchUserIncludeChild,
                                              callback: function ($$v) {
                                                _vm.searchUserIncludeChild = $$v
                                              },
                                              expression:
                                                "searchUserIncludeChild",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "true" } },
                                              [_vm._v("包含下级部门成员")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "false" } },
                                              [_vm._v("仅当前部门成员")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c(
                                      "a-dropdown",
                                      [
                                        _c(
                                          "a-menu",
                                          {
                                            attrs: { slot: "overlay" },
                                            slot: "overlay",
                                          },
                                          [
                                            _c(
                                              "a-menu-item",
                                              {
                                                key: "1",
                                                on: {
                                                  click: _vm.batchChangeOrg,
                                                },
                                              },
                                              [_vm._v("变更部门")]
                                            ),
                                            !_vm.IsProject
                                              ? _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "2",
                                                    on: {
                                                      click:
                                                        _vm.batchChangeStatus,
                                                    },
                                                  },
                                                  [_vm._v("变更状态")]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "a-menu-item",
                                              {
                                                key: "3",
                                                on: { click: _vm.batchDelete },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.IsProject
                                                      ? "批量移除"
                                                      : "批量删除"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a-button",
                                          { staticClass: "m-r-16 m-l-8" },
                                          [
                                            _vm._v("批量操作"),
                                            _c("a-icon", {
                                              attrs: { type: "down" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    !_vm.IsProject
                                      ? _c(
                                          "jtl-button",
                                          {
                                            staticClass: "m-r-16",
                                            on: {
                                              click: _vm.bactchImportHandler,
                                            },
                                          },
                                          [_vm._v("批量导入")]
                                        )
                                      : _vm._e(),
                                    _vm.IsProject
                                      ? _c(
                                          "jtl-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addProjectClick()
                                              },
                                            },
                                          },
                                          [_vm._v("添加成员")]
                                        )
                                      : _c(
                                          "jtl-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: { click: _vm.addClickHandler },
                                          },
                                          [_vm._v("添加成员")]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("a-table", {
                              staticClass: "p-l-16 p-r-16 p-t-4 p-b-12",
                              attrs: {
                                loading: _vm.listLoading,
                                columns: _vm.tableColumns,
                                "data-source": _vm.listData,
                                pagination: _vm.Pagination,
                                scroll: _vm.tableScroll,
                                "row-key": function (record) {
                                  return record.id + "&" + record.enabled
                                },
                                size: _vm.TableSize,
                                "row-selection": {
                                  selectedRowKeys: _vm.selectedRowKeys,
                                  onChange: _vm.onSelectChange,
                                },
                              },
                              on: { change: _vm.tableChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "orgName",
                                    fn: function (text, record) {
                                      return [
                                        _c(
                                          "a-tooltip",
                                          { attrs: { placement: "leftTop" } },
                                          [
                                            _c(
                                              "template",
                                              { slot: "title" },
                                              _vm._l(
                                                record.orgNames,
                                                function (item, index) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: index,
                                                      staticClass: "m-4",
                                                    },
                                                    [_vm._v(_vm._s(item))]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("span", [_vm._v(_vm._s(text))]),
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "enabled",
                                    fn: function (text) {
                                      return [
                                        _c(
                                          "a-tag",
                                          {
                                            attrs: {
                                              color: text ? "green" : "red",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(text ? "正常" : "禁用") +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "action",
                                    fn: function (text, record) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDetail(record)
                                              },
                                            },
                                          },
                                          [_vm._v("详情")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-dropdown",
                                          {
                                            staticClass:
                                              "tree-item-action-wrapper",
                                            attrs: {
                                              trigger: ["click"],
                                              placement: "bottomLeft",
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "tree-item-action-dot",
                                                on: {
                                                  click: function (e) {
                                                    return e.preventDefault()
                                                  },
                                                },
                                              },
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "ellipsis" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "a-menu",
                                              {
                                                attrs: { slot: "overlay" },
                                                slot: "overlay",
                                              },
                                              [
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "0",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.changeOrg(
                                                          record
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "p-12" },
                                                      [_vm._v("变更部门")]
                                                    ),
                                                  ]
                                                ),
                                                !_vm.IsProject
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        key: "1",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.resetPwdClick(
                                                              record
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "p-12",
                                                          },
                                                          [_vm._v("密码管理")]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.IsProject
                                                  ? _c(
                                                      "a-menu-item",
                                                      {
                                                        key: "2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeStatus(
                                                              record.id,
                                                              record.enabled
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "p-12",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "账号" +
                                                                _vm._s(
                                                                  !record.enabled
                                                                    ? "启用"
                                                                    : "禁用"
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "a-menu-item",
                                                  {
                                                    key: "3",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteClickHandler(
                                                          record
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "p-12" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.IsProject
                                                              ? "用户移除"
                                                              : "账号删除"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2093959214
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : [
                _c(
                  "a-card",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex align-center jtl-link m-l-20",
                        on: {
                          click: function ($event) {
                            _vm.isBatchImport = false
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-icon", {
                              staticStyle: { color: "#2393cd" },
                              attrs: { type: "left" },
                            }),
                            _vm._v("返回"),
                          ],
                          1
                        ),
                        _c("h3", { staticClass: "m-20 m-t-16" }, [
                          _vm._v("批量导入成员"),
                        ]),
                      ]
                    ),
                    _c("user-org-batch-import-list-component", {
                      attrs: {
                        type: "user",
                        "import-params": _vm.importParams,
                      },
                      on: {
                        cancel: function ($event) {
                          _vm.isBatchImport = false
                        },
                        ok: _vm.initOrgTree,
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c("user-edit-drawer", {
        ref: "userEditDrawerComponent",
        on: { dialogOK: _vm.initOrgTree },
      }),
      _c("user-change-org-dialog", {
        ref: "userChangeOrgDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.initOrgTree },
      }),
      _c("link-user-department-dialog", {
        ref: "linkUserDepartmentDialog",
        attrs: { type: "user" },
        on: { dialogOK: _vm.initOrgTree },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }