var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component-large",
      attrs: { title: "变更部门", visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "link-user-component form-edit-component-large transform-dialog",
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.listLoading } },
            [
              _c(
                "a-row",
                {
                  staticClass: "transform-header",
                  attrs: { type: "flex", align: "bottom" },
                },
                [
                  _c("a-col", { attrs: { span: 11 } }, [_vm._v(" 选择 ")]),
                  _c("a-col", { attrs: { span: 11, offset: 2 } }, [
                    _vm._v(" 已选"),
                    _c(
                      "a",
                      {
                        staticClass: "transform-remove-all jtl-link pull-right",
                        on: { click: _vm.removeAllClick },
                      },
                      [_vm._v("全部删除")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    {
                      staticClass: "transform-column left-transform-column",
                      attrs: { span: 11 },
                    },
                    [
                      _c(
                        "a-row",
                        {
                          staticStyle: { padding: "8px 16px" },
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c("a-input-search", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请输入部门名",
                                  "allow-clear": "",
                                },
                                on: { search: _vm.initOrgTree },
                                model: {
                                  value: _vm.searchOrgName,
                                  callback: function ($$v) {
                                    _vm.searchOrgName = $$v
                                  },
                                  expression: "searchOrgName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("a-tree", {
                            staticClass: "m-t-16 user-org-tree-custom",
                            attrs: {
                              checkable: "",
                              "auto-expand-parent": true,
                              "default-expand-all": true,
                              "checked-keys": _vm.orgIds,
                              "check-strictly": true,
                              "tree-data": _vm.orgTreeData,
                              "replace-fields": { key: "id" },
                            },
                            on: { check: _vm.onCheck },
                            scopedSlots: _vm._u([
                              {
                                key: "title",
                                fn: function (item) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "flex justify-between" },
                                      [
                                        _c("div", { staticClass: "flex" }, [
                                          item.expanded
                                            ? _c("img", {
                                                staticClass: "tree-icon",
                                                attrs: {
                                                  src: "/img/tree-org-expand.png",
                                                },
                                              })
                                            : _c("img", {
                                                staticClass: "tree-icon",
                                                attrs: {
                                                  src: "/img/tree-org.png",
                                                },
                                              }),
                                          _c("div", { staticClass: "m-l-8" }, [
                                            _vm._v(" " + _vm._s(item.name)),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    {
                      staticClass: "transform-column-middle",
                      attrs: { span: 2 },
                    },
                    [
                      _c("jtl-icon", {
                        staticClass: "center",
                        attrs: { type: "transform" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "transform-column", attrs: { span: 11 } },
                    [
                      _c(
                        "ul",
                        { staticClass: "ul-list" },
                        _vm._l(_vm.RightDataList, function (item, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "jtl-tooltip-component",
                                {
                                  staticClass:
                                    "transform-name transform-name-right text-ellipsis",
                                  attrs: {
                                    title: item.fullName || item.name,
                                    placement: "right",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item.fullName || item.name) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("a-icon", {
                                staticClass: "right-close-icon",
                                attrs: { type: "close-circle" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeItemClick(item.id)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }