





































































import { Component, Ref } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import UserService from '@common-src/service/user-v2';
import { OrgDepartmentQueryModel } from '@common-src/entity-model/org-department-entity';
import OrgDepartmentService from '@common-src/service/org-department';
import { flattenTree } from '@common-src/utils/tree-util';
import { message } from 'ant-design-vue';

@Component
export default class UserChangeOrgDialog extends TransformComponent {
    orgTreeData: Array<any> = null;
    userService: UserService = new UserService();
    searchOrgName: string = null;
    orgIds: Array<string> = null;
    flattenTreeData: Array<any> = null;
    userIds: Array<any> = null;

    created() {
        this.initOrgTree();
    }

    dialogOpen(orgIds: any, userIds: any) {
        this.orgIds = orgIds;
        this.userIds = userIds;
        this.dialogVisible = true;
        this.initOrgTree();
    }

    get RightDataList() {
        return _.filter(this.flattenTreeData, item => _.includes(this.orgIds, item.id));
    }

    initOrgTree() {
        let model = new OrgDepartmentQueryModel();
        model.includeUserInfo = 'true';
        model.orgName = this.searchOrgName;
        OrgDepartmentService.getUserOrgTreeV2(model).then((res) => {
            this.orgTreeData = res;
            this.flattenTreeData = flattenTree(this.orgTreeData, 'children');
        });
    }

    onCheck(checkedKeys: any) {
        this.orgIds = checkedKeys.checked;
    }

    dialogOK() {
        if (_.isEmpty(this.orgIds)) {
            message.warning('请选择要变更的组织');
            return;
        }
        return this.userService.batchChangeOrg({ userIds: this.userIds, orgIds: this.orgIds }).then((ret) => {
            message.success('变更成功');
            this.dialogClose();
            this.$emit('dialogOK');
        });
    }

    removeItemClick(id) {
        const index = _.findIndex(this.orgIds, item => item === id);
        if (index > -1) {
            this.orgIds.splice(index, 1);
        }
    }

    removeAllClick() {
        this.orgIds = [];
    }

    dialogClose(): void {
        this.dialogVisible = false;
    }
}
