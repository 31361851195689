

































































import { Component, Mixins } from 'vue-property-decorator';
import { UserEntityModel, UserResetPwdModel } from '@common-src/entity-model/user-entity';
import DrawerComponent from '@common-src/mixins/drawer-component';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import UserService, { UserResetPwdService } from '@common-src/service/user-v2';
import { message } from 'ant-design-vue';
import { UserStoreModule } from '@common-src/store/modules/user';

@Component
export default class UserEditDrawerComponent extends Mixins(FormDialogComponent, DrawerComponent) {
    model: UserEntityModel = new UserEntityModel();
    passwordType: string = 'hidden';

    get IsProject() {
        return !!UserStoreModule.CurrentProject;
    }

    get PasswordEncrypt() {
        return '*'.repeat(this.model.password?.length);
    }

    drawerShow(model: UserEntityModel, viewModeType: ViewModeType) {
        this.drawerTitle = `${viewModeType === ViewModeType.NEW ? '添加成员' : '成员详情'}`;
        this.drawerVisible = true;
        this.model = model;
        this.viewMode = viewModeType;
        this.passwordType = 'hidden';

        this.$nextTick(() => {
            if (this.jtlForm) {
                this.jtlForm.initForm(this.model, this.viewMode);
            }
        });
    }

    editClickHandler() {
        this.viewMode = ViewModeType.UPDATE;
        if (this.jtlForm) {
            this.jtlForm.initForm(this.model, this.viewMode);
        }
    }

    resetPwdClick(userModel: UserEntityModel) {
        this.$confirm({
            title: '密码管理',
            content: '确定重置密码吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                const userPwdModel = new UserResetPwdModel();
                userPwdModel.id = userModel.id;
                new UserResetPwdService().update(userPwdModel).then(res => {
                    message.success('重置密码成功');
                    this.$emit('dialogOK');
                });
            }
        });
    }

    changeStatus(model: any) {
        let type = model.enabled ? '禁用' : '启用';
        this.$confirm({
            title: `账户${type}`,
            content: `请确认是否将账户设置为${type}状态`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                new UserService().userChangeStatus({ id: model.id, enabled: !model.enabled }).then((res) => {
                    message.success(`${model.enabled ? '禁用' : '启用'}成功`);
                    model.enabled = !model.enabled;
                    this.$emit('dialogOK');
                });
            }
        });
    }

    deleteClickHandler(model: any) {
        this.$confirm({
            title: `账户删除`,
            content: `请确认是否将账户永久删除`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                new UserService().delete(model).then(res => {
                    this.$message.success('删除成功');
                    this.$emit('dialogOK');
                    this.drawerClose();
                });
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            if (this.viewMode === ViewModeType.NEW) {
                new UserService().create(this.jtlForm.formModel as any).then(res => {
                    this.$message.success('添加成功');
                    this.$emit('dialogOK');
                    this.drawerClose();
                });
            }

            if (this.viewMode === ViewModeType.UPDATE) {
                new UserService().update(this.jtlForm.formModel as any).then(res => {
                    this.$message.success('修改成功');
                    this.$emit('dialogOK');
                    this.drawerClose();
                });
            }
        }).catch(err => {
            throw err;
        });
    }
}
