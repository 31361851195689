











































































































































































































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { UserEntityModel, UserResetPwdModel, UserQueryModel } from '@common-src/entity-model/user-entity';
import UserService, { UserResetPwdService } from '@common-src/service/user-v2';
import CommonService from '@common-src/service/common';
import UserEditDrawerComponent from './user-edit-drawer.vue';
import { ViewModeType, OrgTreeType } from '@common-src/model/enum';
import { UserStoreModule } from '@common-src/store/modules/user';
import { message } from 'ant-design-vue';
import { flattenTree, findTreeLeafNodesId } from '@common-src/utils/tree-util';
import OrgDepartmentService from '@common-src/service/org-department';
import { OrgDepartmentQueryModel, OrgDepartmentEntityModel, formatOrgTreeType } from '@common-src/entity-model/org-department-entity';
import UserChangeOrgDialog from './user-change-org-dialog.vue';
import UserOrgBatchImportListComponent from './user-org-batch-import.vue';
import LinkUserDepartmentDialog from '../role/link-user-department-dialog.vue';

@Component({
    components: {
        'user-edit-drawer': UserEditDrawerComponent,
        'user-change-org-dialog': UserChangeOrgDialog,
        'user-org-batch-import-list-component': UserOrgBatchImportListComponent,
        'link-user-department-dialog': LinkUserDepartmentDialog
    }
})
export default class UserListComponent extends TableDialogFormComponent<UserEntityModel, UserQueryModel> {
    UserEntityModel = UserEntityModel;
    userService: UserService = new UserService();
    formatOrgTreeType = formatOrgTreeType;
    OrgTreeType = OrgTreeType;
    defaultPassword: string = null;
    expandedKeys: any = [];
    selectedKeys: any = [];
    defaultExpandkeys: any = [];
    searchOrgName: string = null;
    searchKeyword: string = null;
    searchEnable: any = null;
    searchUserIncludeChild: any = 'true';
    treeData = [];
    isBatchImport = false;
    importParams: any = {};
    flattenTreeData = [];
    selectedOrg: any = {
        name: '',
        userNum: '0'
    };

    get IsProject() {
        return !!UserStoreModule.CurrentProject;
    }

    created() {
        this.initTable({
            service: OrgDepartmentService,
            tableColumns: UserEntityModel.getTableColumns()
        });
        !this.IsProject && (CommonService.getPageSettingInfo().then((res) => {
            this.defaultPassword = res.defaultPassword;
        }));
        this.searchEnable = undefined;
        this.initOrgTree();
    }

    searchHandlerClick() {
        this.selectedKeys = [];
        this.initOrgTree();
    }

    initOrgTree() {
        console.log('initOrgTree');

        let model = new OrgDepartmentQueryModel();
        model.includeUserInfo = 'true';
        model.orgName = this.searchOrgName;
        OrgDepartmentService.getUserOrgTreeV2(model).then((res) => {
            this.treeData = res || [];
            this.flattenTreeData = flattenTree(this.treeData, 'children');
            if (!_.isEmpty(this.treeData)) {
                _.forEach(this.treeData, item => {
                    this.defaultExpandkeys.push(item.id);
                });

                if (!_.isEmpty(this.selectedKeys)) {
                    if (_.find(this.flattenTreeData, r => r.id === this.selectedKeys[0])) {
                        this.onSelect([this.selectedKeys[0]]);
                    }
                } else {
                    this.selectedOrg = this.treeData[0];
                    this.onSelect([this.treeData[0].id]);
                }

                this.expandedKeys.push(...this.defaultExpandkeys);
            } else {
                this.selectedOrg = { name: '', userNum: '0' };
                this.listData = [];
            }
        });
    }

    deleteOrgClick(userModel: UserEntityModel) {
        const userId = userModel.id;
        this.userService.deleteOrgUser(userId).then(res => {
            this.showMessageSuccess('删除成功');
            this.initUser();
        });
    }

    onExpand(expandedKeys:any, { expanded: bool, node }:any) {
        this.expandHandler({ expanded: node.expanded, ...node.dataRef });
    }

    expandHandler(item: any) {
        if (item.expanded) {
            const ids = findTreeLeafNodesId(item.children);
            _.forEach([...ids, item.id], r => {
                const index = _.findIndex(this.expandedKeys, (key) => {
                    return key === r;
                });
                if (index > -1) {
                    this.expandedKeys.splice(index, 1);
                }
            });
        } else {
            this.expandedKeys.push(item.id);
        }
    }

    onSelect(selectedKeys: any) {
        this.selectedKeys = selectedKeys;
        if (!_.isEmpty(this.selectedKeys)) {
            this.initUser();
            this.selectedOrg = _.find(this.flattenTreeData, r => r.id === this.selectedKeys[0]);
        }
    }

    bactchImportHandler() {
        this.isBatchImport = true;
        this.importParams = {
            orgId: _.get(this.selectedKeys, '[0]'),
            keyword: this.searchKeyword,
            enabled: this.searchEnable,
            userIncludeChild: this.searchUserIncludeChild
        };
    }

    tableChange(pagination: any) {
        this.page = pagination.current;
        this.pageSize = pagination.pageSize;
        this.initUser();
    }

    searchHandler() {
        this.page = 1;
        this.initUser();
    }

    initUser() {
        this.listLoading = true;
        const body = {
            params: {
                orgId: this.selectedKeys[0],
                keyword: this.searchKeyword,
                enabled: this.searchEnable,
                userIncludeChild: this.searchUserIncludeChild
            },
            page: this.Pagination.current,
            limit: this.Pagination.pageSize
        };
        new UserService().getUserByOrgTreeV2(body).then(res => {
            this.listData = _.get(res, 'items') || [];
            this.selectedRowKeys = [];
            this.total = _.get(res, 'Total') || 0;
        }).finally(() => {
            this.listLoading = false;
        });
    }

    addProjectClick() {
        if (_.isEmpty(this.selectedKeys)) {
            message.warning('请先选择部门');
            return;
        }
        (this.$refs.linkUserDepartmentDialog as LinkUserDepartmentDialog).dialogOpen(this.selectedKeys[0], []);
    }

    formDialogOK() {
        this.onSelect(this.selectedKeys || [this.treeData[0].id]);
    }

    addClickHandler() {
        const model = new UserEntityModel(_.isEmpty(this.selectedKeys) ? null : [this.selectedKeys[0]]);
        model.password = UserStoreModule.DefaultConfigPassword;
        (this.$refs.userEditDrawerComponent as UserEditDrawerComponent).drawerShow(model, ViewModeType.NEW);
    }

    addOrgClick(record: any) {
        const model = new OrgDepartmentEntityModel('project');
        model.parentId = record.id;
        model.projectParentId = record.id;
        this.addClick(model as any);
    }

    editOrgClick(record: any) {
        OrgDepartmentService.retrieve(record.id).then((res) => {
            const model = new OrgDepartmentEntityModel('project').toModel(res);
            model.type = 'project';
            console.log('model', model);
            this.editClick(model);
        });
    }

    delOrgClick(record: any) {
        this.$confirm({
            title: `组织删除`,
            content: `请确认是否将组织永久删除`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                this.deleteClick(record).then(res => {
                    this.initOrgTree();
                });
            }
        });
    }

    moveClick(record: any, type: string) {
        OrgDepartmentService.moveOrg({ id: record.id, moveType: type }).then((res) => {
            message.success('移动成功');
            this.initOrgTree();
        });
    }

    showDetail(record:any) {
        const model = new UserEntityModel().toModel(record);
        (this.$refs.userEditDrawerComponent as UserEditDrawerComponent).drawerShow(model, ViewModeType.VIEW);
    }

    resetPwdClick(userModel: UserEntityModel) {
        this.$confirm({
            title: '密码管理',
            content: '确定重置密码吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                const userPwdModel = new UserResetPwdModel();
                userPwdModel.id = userModel.id;
                new UserResetPwdService().update(userPwdModel).then(res => {
                    message.success('重置密码成功');
                });
            }
        });
    }

    changeStatus(id: any, type: boolean, mode: string = 'single') {
        const typeMsg = type ? '设置为禁用' : '恢复为启用';
        this.$confirm({
            title: `账户${type ? '禁用' : '启用'}`,
            content: `请确认是否${mode === 'batch' ? '批量' : ''}将账户${typeMsg}状态`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                if (mode === 'batch') {
                    new UserService().userBatchChangeStatus({ userIds: id, enabled: !type }).then((res) => {
                        message.success(`${type ? '批量禁用' : '批量启用'}成功`);
                        this.initUser();
                    });
                } else {
                    new UserService().userChangeStatus({ id: id, enabled: !type }).then((res) => {
                        message.success(`${type ? '禁用' : '启用'}成功`);
                        this.initUser();
                    });
                }
            }
        });
    }

    deleteClickHandler(model: any) {
        this.$confirm({
            title: `${this.IsProject ? '用户移除' : '账户删除'}`,
            content: `请确认是否将${this.IsProject ? '用户永久移除' : '账户永久删除'}`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                if (this.IsProject) {
                    OrgDepartmentService.batchDeleteOrgUser({ userIds: [model.id] }).then(res => {
                        message.success(`移除成功`);
                        this.initOrgTree();
                    });
                } else {
                    new UserService().delete(model).then(res => {
                        message.success(`删除成功`);
                        this.initOrgTree();
                    });
                }
            }
        });
    }

    asyncOrg() {
        OrgDepartmentService.syncFromCorp().then(res => {
            message.success('同步成功');
            this.initOrgTree();
        });
    }

    batchChangeOrg() {
        if (_.isEmpty(this.selectedRowKeys)) {
            message.warning('请选择要操作的用户');
            return;
        }
        const ids = _.map(this.selectedRowKeys, (id) => {
            return _.first(_.split(id, '&'));
        });
        (this.$refs.userChangeOrgDialog as UserChangeOrgDialog).dialogOpen([], ids);
    }

    changeOrg(model: any) {
        (this.$refs.userChangeOrgDialog as UserChangeOrgDialog).dialogOpen(model.orgIds, [model.id]);
    }

    batchChangeStatus() {
        if (_.isEmpty(this.selectedRowKeys)) {
            message.warning('请选择要操作的用户');
            return;
        }
        const ids = [];
        const type = _.last(_.split(this.selectedRowKeys[0], '&'));
        const isSame = _.some(this.selectedRowKeys, (item) => {
            const data = _.split(item, '&');
            ids.push(_.first(data));
            return _.last(data) !== type;
        });
        if (isSame) {
            message.warning('请选择账号状态为同一类型的用户进行操作');
            return;
        }
        this.changeStatus(ids, type === 'true', 'batch');
    }

    batchDelete() {
        if (_.isEmpty(this.selectedRowKeys)) {
            message.warning('请选择要操作的用户');
            return;
        }
        const ids = _.map(this.selectedRowKeys, (id) => {
            return _.first(_.split(id, '&'));
        });

        this.$confirm({
            title: `${this.IsProject ? '用户移除' : '账户删除'}`,
            content: `请确认是否批量将${this.IsProject ? '用户移除' : '账户永久删除'}`,
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                if (this.IsProject) {
                    OrgDepartmentService.batchDeleteOrgUser({ userIds: ids }).then(res => {
                        message.success(`移除成功`);
                        this.initOrgTree();
                    });
                } else {
                    new UserService().batchDelete({ userIds: ids }).then(() => {
                        message.success(`批量删除成功`);
                        this.initOrgTree();
                    });
                }
            }
        });
    }
}

